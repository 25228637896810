import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageSlider from "./ImageSlider.jsx";
import {extractImageSrcFromHTML} from "../../utils/helpers"

function Slide() {

    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        // console.log('-------------');
        const key = 'AIzaSyDIR-jl2tOxKjQMyQseN3VtRVYUHdP9xfI';
        const blogIdKznak = '7918289366205167931';
        fetch(`https://www.googleapis.com/blogger/v3/blogs/${blogIdKznak}/posts?key=${key}`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                const blogs = data.items;
                const result = [];
                blogs.slice(0, 5).forEach(b => {
                    const imageTags = extractImageSrcFromHTML(b.content);

                    const imageFromBlog = imageTags.length ? imageTags[0] : 'https://1000logos.net/wp-content/uploads/2021/05/Blogger-logo.png';
                    result.push({
                        title: b.title,
                        image: imageFromBlog,
                        url: b.url
                    })
                });
                setBlogPosts(result);
            })
            .catch(error => console.error(error, 'xxxxxxxxxxxxxxxxxxxx'));
    }, []);

    console.log("<<<<blogPosts>>>>");
    console.log(blogPosts);
    return (
        <>
            <Main>
                {blogPosts.length && <ImageSlider slides={blogPosts} />}
            </Main>
        </>
    );
};

export default Slide;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 95%;
    /* height: 50vh; */
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 900px){
        flex-direction: row;
        height: auto;
    }
`;
