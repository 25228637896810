import React from "react";
import styled from "styled-components";
import Coworkers from "./about/coworkers";
import Footer from "./about/footer";
import Header from "./about/header";
import Slider2 from "./about/slider2";
import Slider3 from "./about/slider3";

export default function About(){
    return(
        <Main>
            <Header/>
            <Space>
                <SpaceBr/>
            </Space>
            <Slider2/>
            <Space>
                <SpaceBr/>
            </Space>
            <Slider3/> 
            <Space>
                <SpaceBr/>
            </Space>
            <Footer/>
            <Space>
                <SpaceBr/>
            </Space>
            <Coworkers/>
        </Main>
    )
}



const Main = styled.div`
    padding: 60px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Space = styled.div`
    display: flex;
    width: 100%;
    height: 150px;
    border-radius: 10px;
    align-items: center;
`;
const SpaceBr = styled.div`
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    border: 1px #eeeeee solid;
    border-radius: 10px;
`;