import React from 'react'
import styled from 'styled-components';
import LogoWhite from '../../images/logoWhite.svg';
import EuLogo from '../../images/europe-logo.png'

export default function Header() {
    return (
        <Main>
            <img src={EuLogo} alt="" />
            <HeaderText>
                ԿԱՐՈՂՈՒՆԱԿՈՒԹՅՈՒՆՆԵՐԻ  ԿՈՂՄՆՈՐՈՇԻՉ ՇՐՋԱՆԱԿ՝ ԺՈՂՈՎՐԴԱՎԱՐԱԿԱՆ ՄՇԱԿՈՒՅԹԻ ՀԱՄԱՐ
            </HeaderText>
            {/* <img src={LogoWhite} alt="" /> */}
        </Main>
    )
}


const HeaderText = styled.p`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: 25px;
    color: white;
    width: 80%;
    font-weight: 700;
    @media (max-width: 450px) {
        font-size: 18px;
        width: 100%;
    }
`;



const Main = styled.div`
    /* padding: 60px; */
    padding: 20px 0;
    background-color: #123751;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    scroll-behavior: smooth;
    justify-content: space-evenly;
    @media (max-width: 1000px) {
        padding: 10px;
        width: 95%;
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
    img {
        width: 100px;
    }
`;