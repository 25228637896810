import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageSlider2 from "./ImageSlider2.jsx";

function Slide2() {

    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        const key = 'AIzaSyDIR-jl2tOxKjQMyQseN3VtRVYUHdP9xfI';
        const blogIdLibrary = '8918650962768510857';
        fetch(`https://www.googleapis.com/blogger/v3/blogs/${blogIdLibrary}/posts?key=${key}`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                // console.log(data, "data----------------222222222222222");
                const blogs = data.items;
                const result = [];
                blogs.slice(0, 5).forEach(b => {
                    

                    let myRegexp = / src=\\?"(.+(\.jpg|\.jpeg))\\?"/gi;
                    let match = myRegexp.exec(b.content);
                    // match && console.log(b.content);

                    const imageFromBlog = match ? match[1] : 'https://1000logos.net/wp-content/uploads/2021/05/Blogger-logo.png';
                    
                    // console.log(imageFromBlog, "imageFromBlog")
                    result.push({
                        title: b.title,
                        image: imageFromBlog,
                        url: b.url
                    })
                });
                setBlogPosts(result);
            })
            .catch(error => console.error(error, 'xxxxxxxxxxxxxxxxxxxx'));
    }, [])
    return (
        <>
        {/* {console.log(blogPosts, "blogPosts<<<<")} */}
            <Main>
                {blogPosts.length && <ImageSlider2 slides={blogPosts} />}
            </Main>
        </>
    );
};

export default Slide2;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 95%;
    /* height: 50vh; */
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 900px){
        flex-direction: row;
        height: auto;
    }
`;