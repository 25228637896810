import React from "react";
import styled from "styled-components";
import adviceJpg from "../../images/xorhurd.jpg"

export default function Advice() { 
    return (
        <Main>
            <Body>
                <Block>
                    <HeaderText>Հիմնադրամի Խորհուրդը</HeaderText>
                    <ContentText>
                        Հիմնադրամի կառավարման բարձրագույն և հսկողություն իրականացնող մարմինը խորհուրդն է: Խորհրդի անհատական կազմը հաստատվում է Հայաստանի Հանրապետության վարչապետի որոշմամբ՝ լիազոր մարմնի ղեկավարի ներկայացմամբ: Խորհուրդը բաղկացած է 17 անդամից, որոնցից 1-ը՝ ի պաշտոնե լիազոր մարմնի ղեկավարն է, 8-ը պետական կառավարման մարմինների ներկայացուցիչներ են, իսկ 8-ը՝ լիազոր մարմնի ղեկավարի ներկայացմամբ՝ հասարակական կազմակերպությունների ներկայացուցիչներ։
                    </ContentText>
                    <Button>
                        <ButtonText>Ավելին</ButtonText>
                    </Button>
                </Block>
                <Block>
                    <Background />
                </Block>
            </Body>
        </Main>
    )
}

const Main = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
    max-width: 95%;
`;

const Block = styled.div`
    font-family: 'Noto Sans Armenian', sans-serif;
    display: flex;
    font-size: 16px;
    width: 50%;
    flex-direction: column;
    @media (max-width: 950px) {
        width: 100%;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 950px) {
    flex-direction: column;
        
    }
`;

const Background = styled.div`
    width: 100%;
    height: 400px;
    background: url(${adviceJpg});
    background-size: cover;
    background-repeat: no-repeat;
`;

const ButtonText = styled.p`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: 1s;
    margin: 0;
    padding: 0;
    color: #535ba0;
    font-size: 20px;
    font-family: 'Noto Sans Armenian', sans-serif;
    &:hover{
        color: white;
    }
`;

const Button = styled.button`
    text-align: center;
    align-items: center;
    margin: 0;
    margin-top: 20px;
    padding: 0;
    justify-content: center;
    transition: 1s;
    width: 150px;
    height: 50px;
    border-radius: 8px;
    border: 2px solid #535ba0;
    background: none;
    &:hover{
        background-color: #535ba0;
    }
`;

const HeaderText = styled.h1`
    font-size: 22px;
    margin: 0;
    padding: 0;
`;

const ContentText = styled.p`
    width: 90%;
`;