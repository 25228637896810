import React from 'react'
import styled from 'styled-components';
import { useState } from 'react';
import Verapatrastum from "../../images/verapatrastum.jpg";


export default function Slider3() {

    const [show1, setShow1] = useState("0%");
    const Hidden = [
        {
            opacity: show1,
        }
    ];
    const Start = {
        textAlign: "start"
    }

    return (
        <Main>
            <Text>Վերապատրաստման ծրագրերի մշակման և արդյունավետության գնահատման բաժինը ձգտում է ապահովել բարձր որակի վերապատրաստումների հասանելիությունը բոլոր մանկավարժների համար։</Text>
            <br />
            <br />
            <br />
            <br />
            <Horizonal>
                <Block background1
                    onMouseEnter={() => setShow1("100%")}
                    onMouseLeave={() => setShow1("0%")}
                >
                    <Blur>
                        <Section>
                            <FixedText>Վերապատրաստման ծրագրերի մշակման և արդյունավետության գնահատման բաժին</FixedText>
                        </Section>
                        <Section style={Start}>
                            <FixedHiddenText style={Hidden[0]}>
                                <ul>
                                    Մշակում է
                                    <br />
                                    <br />
                                    <li>մանկավարժական աշխատողների ուսուցման և ինքնուսուցման մեթոդներ,</li>
                                    <li>մանկավարժական աշխատողների ընթացիկ և արտահերթ ատեստավորման վերապատրաստումների ծրագրերի նախագծեր,</li>
                                    <li>մանկավարժական աշխատողների մասնագիտական զարգացման դասընթացներ,
                                    </li>
                                    <li>դպրոցի վարչական աշխատողների վերապատրաստումների ծրագրերի նախագծեր,
                                    </li>
                                    <li>դպրոցի կառավարման և ղեկավարման հմտությունների զարգացման նպատակով վերապատրաստումների պլաններ,
                                    </li>
                                    <li>դպրոցի վարչական աշխատողների մասնագիտական զարգացման դասընթացներ:
                                    </li>
                                </ul>
                            </FixedHiddenText>
                        </Section>
                        <Section>
                            <Button>
                                <ButtonText>Ավելին</ButtonText>
                            </Button>
                        </Section>
                    </Blur>
                </Block>
            </Horizonal>
        </Main>
    )
}


const Main = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Blur = styled.div`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    display: flex;
    flex-direction: column;
    transition: 1s;
    &:hover{
        /* backdrop-filter: blur(8px); */
        /* background-color: rgba(0, 0, 0, 0.4); */
    }
`;

const Horizonal = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    align-items:center;
    justify-content:center;
    @media(max-width: 1000px){
        flex-direction: column;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    transition: 0.6s;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 900px;
    background: url(${Verapatrastum}) center;
        background-size: cover;
        background-repeat: no-repeat;
    &:hover{
        width: 200%;
        @media (max-width: 1000px){
            width: 100%;
        }
    }
`;

const Text = styled.h1`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.5s;
    color: black;
    font-size: 24px;
`;
const Section = styled.div`
    display: flex;
    flex-direction: row;
    transition: 0.2s ease-out;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 30%;
`;

const FixedText = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: .3s;
    color: white;
    filter: drop-shadow(5px 5px 10px black);
    font-size: 20px;
`;

const FixedHiddenText = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    font-size: 20px;
    line-height: 30px;
    color: white;
    filter: drop-shadow(0px 2px 5px black);
    font-size: 17px;
    @media (max-width: 700px) {
        font-size: 16px;
    line-height: 22px;

    }
`;

const ButtonText = styled.p`
    transition: 1s;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 20px;
    font-family: 'Noto Sans Armenian', sans-serif;
    &:hover{
        color: white;
    }
`;

const Button = styled.button`
    text-align: center;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    transition: 1s;
    width: 150px;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid white;
    filter: drop-shadow(4px 4px 10px #535ba0);
    background: none;
    &:hover{
        background-color: #535ba0;
    }
`;
