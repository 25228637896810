import React from 'react'
import styled from 'styled-components';

export default function Coworkers() {
  return (
    <Main>
        <Header>
            <HeaderText>Մեր Գործընկերները</HeaderText>
        </Header>
    </Main>
  )
}



const Main = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const HeaderText = styled.h1`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-weight: 600;
`;

