import React from 'react'
import styled from 'styled-components';
import WhiteLogo from '../../images/logoWhite.svg';
import RandomImage from '../../images/banner1.svg';


export default function Header() {
    return (
        <Main>
            <MainBlock>
                <Blur>
                    <TextBlock>
                        <HeaderText>
                            Հանրակրթության պետական չափորոշիչ
                        </HeaderText>
                        <DescriptionText>
                            Հանրակրթության պետական չափորոշիչը ելնում է կարողունակությունների վրա հիմնված մոտեցումից և սահմանում է շրջանավարտներին ներկայացվող որակական պահանջները:
                        </DescriptionText>
                    </TextBlock>
                    <LogoBlock>
                        <img src={WhiteLogo} alt='' />
                    </LogoBlock>
                </Blur>
            </MainBlock>
        </Main>
    )
}


const Main = styled.div`
    /* padding: 60px; */
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const TextBlock = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 70%;
    @media (max-width: 1000px) {
        width: 100%;
    }
`;

const LogoBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 150px;
    height: 150px; 

    img {
        width: 95%; 
        color: white !important;
    }
`;

const MainBlock = styled.div`
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(4px 4px 10px #535ba0);
    background-image: url(${RandomImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Blur = styled.div`
    display: flex;
    border-radius: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 15px 40px 15px 40px;
    backdrop-filter: blur(7px);
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

const HeaderText = styled.p`
    font-size: 25px;
    margin: 0;
    font-weight: 700;
    color: white;
    font-family: 'Noto Sans Armenian', sans-serif;
`;

const DescriptionText = styled.p`
    font-size: 17px;
    opacity: .8;
    font-weight: 500;
    width: 100%;
    color: white;
    font-family: 'Noto Sans Armenian', sans-serif;
`;


// const Main = styled.div`
//     /* padding: 60px; */
//     height: auto;
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     scroll-behavior: smooth;
//     justify-content: space-between;
//     @media (max-width: 1000px) {
//     /* padding-left: 10px;
//     padding-right: 10px; */
//   }
// `;


// const Column = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 80%;
//     height: 100%;
// `;

// const Text = styled.p`
//     font-family: 'Noto Sans Armenian', sans-serif;
//     font-size: 20px;
//     color: white;
// `;

// const Row = styled.div`
//     display: flex;
//     /* background-image: url(${RandomImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover; */
//     /* filter: drop-shadow(4px 4px 10px #535ba0); */

//     justify-content: space-between;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: auto;
//     align-items: center;
//     @media (max-width: 1300px){
//        flex-direction: column;
//     }
// `;

// const Blur = styled.div`
//     filter: drop-shadow(4px 4px 10px #535ba0);
//     /* filter: blur(8px); */
//     align-items: center;
//     display: flex;
//     padding: 40px;
//     backdrop-filter: blur(8px);
//     width: 100%;
//     height: 100%;
//     background-image: url(${RandomImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     @media (max-width: 1300px){
//        flex-direction: column;
//     }
// `;

// const MainText = styled.h1`
//     color: white;
//     font-family: 'Noto Sans Armenian', sans-serif;
//     font-size: 36px;
//     font-weight: 700;
//     @media (max-width: 700px) {
//         font-size: 30px;
//     }
// `;


// const ImageDiv = styled.div`
//     height: 300px;
//     width: 300px;
//     background-image: url(${BlueLogo});
//     background-position: center;
//     background-size: cover;
//     background-repeat: no-repeat;
// `;
