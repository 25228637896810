import React from 'react'
import styled, { css } from 'styled-components';
import { useState } from 'react';
import Slider3_1 from "../../images/slider3-1.jpg"
import Slider3_2 from "../../images/slider3-2.jpg"
import Slider3_3 from "../../images/slider3-3.jpg"


export default function Slider3() {

    const [show1, setShow1] = useState("0%");
    const [show2, setShow2] = useState("0%");
    const [show3, setShow3] = useState("0%");

    const Hidden = [
        {
            opacity: show1,
        },
        {
            opacity: show2
        },
        {
            opacity: show3,
        }
    ];

    const Start = {
        textAlign: "start"
    }

    return (
        <Main>
            <Text>Կրթության բովանդակության մշակման վարչություն</Text>
            <SmallText>Կրթության բովանդակության մշակման վարչությունը ուսումնական հաստատությունների ուսուցիչներից և դաստիարակներից մինչև ծնողներ, յուրաքանչյուր երեխայի ներուժը բացահայտելու և սովորելու մղման բանալին է: Մենք աջակցում ենք բոլոր մանկավարժներին սովորեցնելու երեխաներին՝ տրամադրելով լավագույն կրթական ռեսուրսներ, որոնք կօգտագործվեն տանը, դպրոցում և ամենուր:

            </SmallText>
            <br />
            <br />
            <br />
            <br />
            <Horizonal>
                <Block background1
                    onMouseEnter={() => setShow1("100%")}
                    onMouseLeave={() => setShow1("0%")}
                >
                    <Blur>
                        <Section>
                            <FixedText>Չափորոշչի լրամշակման և զարգացման բաժին</FixedText>
                        </Section>
                        <Section style={Start}>
                            <FixedHiddenText style={Hidden[0]}>
                                <ul>
                                    Իրականացնում է
                                    <br />
                                    <br />
                                    <li>ՀՊՉ կարողունակությունների լրամշակում,</li>
                                    <li>ՀՊՉ և առարկայական չափորոշիչներում վերջնարդյունքերի լրամշակում,</li>
                                    <li>ՀՊՉ հենքային ուսումնական պլանի հիման վրա օրինակելի պլանների նախագծերի մշակում,
                                    </li>
                                    <li>այլընտրանքային ուսումնական պլանի մշակման մեթոդական ուղեցույցների մշակում:
                                    </li>
                                </ul>
                            </FixedHiddenText>
                        </Section>
                        <Section>
                            <Button>
                                <ButtonText>Ավելին</ButtonText>
                            </Button>
                        </Section>
                    </Blur>
                </Block>
                <Block background2
                    onMouseEnter={() => setShow2("100%")}
                    onMouseLeave={() => setShow2("0%")}
                >
                    <Blur>
                        <Section>
                            <FixedText>Ուսումնական բնագավառների մշակման և զարգացման բաժին
                            </FixedText>
                        </Section>
                        <Section style={Start}>
                            <FixedHiddenText style={Hidden[1]}>
                                <ul>
                                    Մշակում է
                                    <br />
                                    <br />
                                    <li>բնագավառի օրինակելի առարկայացանկի ձևավորման մեթոդներ,,</li>
                                    <li>բնագավառի օրինակելի առարկայացանկի ձևավորման ուղեցույցներ,
                                    </li>
                                    <li>առարկայի ծրագրի վերջնարդյունքներին առարկայի բովանդակության համապատասխանության գնահատման մեթոդներ,
                                    </li>
                                    <li>բնագավառի պահանջների կատարումն ապահովող դասավանդման մեթոդներ
                                    </li>
                                </ul>
                            </FixedHiddenText>
                        </Section>
                        <Section>
                            <Button>
                                <ButtonText>Ավելին</ButtonText>
                            </Button>
                        </Section>
                    </Blur>
                </Block>
                <Block background3
                    onMouseEnter={() => setShow3("100%")}
                    onMouseLeave={() => setShow3("0%")}
                >
                    <Blur>
                        <Section>
                            <FixedText>Կրթության ոլորտի հետազոտությունների բաժին</FixedText>
                        </Section>
                        <Section style={Start}>
                            <FixedHiddenText style={Hidden[2]}>
                                <ul>
                                    Մշակում է
                                    <br />
                                    <br />
                                    <li>Գնահատման մեթոդաբանություն և համապատասխան գործիքներ,</li>
                                    <li>ոչ ֆորմալ և ինֆորմալ կրթության արդյունքների ճանաչման համակարգի նախագիծ</li>
                                    <li>ներքին գնահատման սկզբունքներ և իրականացնում է արդյունքների վերլուծություն:
                                    </li>
                                </ul>
                            </FixedHiddenText>
                        </Section>
                        <Section>
                            <Button>
                                <ButtonText>Ավելին</ButtonText>
                            </Button>
                        </Section>
                    </Blur>
                </Block>
            </Horizonal>
        </Main>
    )
}


const Main = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Blur = styled.div`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    display: flex;
    flex-direction: column;
    transition: 1s;
    &:hover{
        /* backdrop-filter: blur(8px); */
        /* background-color: rgba(0, 0, 0, 0.4); */

    }
`;

const Horizonal = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    align-items:center;
    justify-content:center;
    @media(max-width: 1000px){
        flex-direction: column;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    transition: 0.6s;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 700px;
    &:hover{
        width: 200%;
        @media (max-width: 1000px){
            width: 100%;
        }
    }
    ${props => props.background1 && css`
        background: url(${Slider3_1}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
    ${props => props.background2 && css`
        background: url(${Slider3_2}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
    ${props => props.background3 && css`
        background: url(${Slider3_3}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
`;

const Text = styled.h1`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    color: black;
    font-size: 24px;
`;
const SmallText = styled.p`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    color: black;
    font-size: 20px;
`;
const Section = styled.div`
    display: flex;
    flex-direction: row;
    transition: 0.6s ease-out;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 30%;
`;

const FixedText = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    color: white;
    filter: drop-shadow(5px 5px 10px black);
    font-size: 23px;
`;

const FixedHiddenText = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    font-size: 20px;
    line-height: 30px;
    color: white;
    filter: drop-shadow(0px 2px 5px black);
    font-size: 18px;
    @media (max-width: 700px) {
        line-height: 22px;
        font-size: 17px;
    }
`;

const ButtonText = styled.p`
    transition: 1s;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 20px;
    font-family: 'Noto Sans Armenian', sans-serif;
    &:hover{
        color: white;
    }
`;

const Button = styled.button`
    text-align: center;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    transition: 1s;
    width: 150px;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid white;
    filter: drop-shadow(4px 4px 10px #535ba0);
    background: none;
    &:hover{
        background-color: #535ba0;
    }
`;
