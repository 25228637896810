import React from 'react'
import styled from 'styled-components';

function Content() {
  return (
    <Main>
      <Block>
        <InputDiv>
          <Input placeholder='Ձեր անունը․․․'></Input>
        </InputDiv>
        <InputDiv>
          <Input placeholder='Ձեր էլ․ հասցեն․․․'></Input>
        </InputDiv>
        <TextAreaDiv>
          <TextArea placeholder='Ձեր նամակը․․․'></TextArea>
        </TextAreaDiv>
        <ButtonDiv>
          <SendButton>Ուղարկել</SendButton>
        </ButtonDiv>
      </Block>
      <Block>
        <Mapp src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d381.1718776582852!2d44.51062891384004!3d40.15620319947932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abc6daf95412f%3A0xaf0e8dad9350c2f3!2zNjcgVGlncmFuIE1ldHMgQXZlLCBZZXJldmFuIDAwMDUsINCQ0YDQvNC10L3QuNGP!5e0!3m2!1sru!2s!4v1623655722080!5m2!1sru!2s" width="90%" height="570px" loading="lazy">

        </Mapp>
      </Block>
    </Main>
  )
}

export default Content;


const Main = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const Mapp = styled.iframe`
   width: 100%;
   height: 570px;
   border: 0;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 20px;
    }
`;

const InputDiv = styled.div`
  height: 100px;
  @media(max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
`;

const ButtonDiv = styled.div`
  height: 50px;
  @media(max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
`;

const SendButton = styled.button`
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    /* padding: 8px 20px; */
    height: 50px;
    width: fit-content;
    color: #535ba0;
    font-weight: 700;
    background-color: transparent;
    border: 2px solid #535ba0;
    transition: all 0.5s;
    cursor: pointer;
`;

const TextAreaDiv = styled.div`
  margin-bottom: 50px;
  @media(max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
`;
const Input = styled.input`
  height: 50px;
  border: 2px solid #eee;
  font-size: 15px;
  border-radius: 0px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  line-height: 20px;
  color: #7a7a7a;
  width: 60%;
  padding-left: 20px;
  font-weight: 200;
  @media (max-width: 1200px) {
        width: 100%;
    }
`;

const TextArea = styled.textarea`
  width: 60%;
  font-size: 15px;
  font-weight: 200;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border-radius: 0px;
  line-height: 20px;
  border: 2px solid #eee;
  padding-left: 20px;
  padding-top: 20px;
  height: 200px;
  @media (max-width: 1200px) {
        width: 100%;
    }
`;