import React from 'react';
import Navbar from './Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import Contact from '../pages/contact';
import About from '../pages/about';
import Plan from '../pages/plan';
import Year from '../pages/year';
import Page1 from '../pages/page1';
import Page2 from '../pages/page2';
import Page3 from '../pages/page3';

function Routing() {
  return (
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/plan' element={<Plan/>} />
          <Route path='/year' element={<Year/>} />
          <Route path='/page1' element={<Page1/>} />
          <Route path='/page2' element={<Page2/>} />
          <Route path='/page3' element={<Page3/>} />
        </Routes>
      </Router>
  );
}

export default Routing;