import React from 'react'
import styled from 'styled-components';
import Doc3 from './images/page3.jpg'

export default function Part3() {
    return (
        <Main>
            <Row1>
                <Block>
                    <TextBlock>
                        <HeaderText>
                            Հատոր 3․ Կարողունակությունների բնութագրիչներ։
                        </HeaderText>
                        <DescriptionText>
                            Այստեղ ուսումնասիրվում է, թե ժողովրդավարական մշակույթի կարողունակությունների (ԺՄԿ) մոդելն ինչպես կարող է կիրառվել կրթական չափորոշչի և ծրագրերի նախագծման ու կազմման գործընթացում՝ կրթակարգի բարեփոխումների պատասխանատուների կողմից. օրինակ՝ ինչպես կարելի է այն օգտագործել գործող կրթական չափորոշչի և ծրագրերի հաստատման և հետագա լրամշակման կամ նոր կրթական չափորոշչի ու ծրագրերի պլանավորման ընթացքում։
                        </DescriptionText>
                        <DownloadButton>
                            <a href="https://rm.coe.int/arm-reference-framework-vol-3/1680a1b758" rel='noopener noreferrer' target='_blank'>
                                Հատոր 3․ Հայերեն տարբերակը
                            </a>
                        </DownloadButton>
                    </TextBlock>
                    <ImageBlock>
                        <img src={Doc3} alt='' />
                    </ImageBlock>
                </Block>
            </Row1>
        </Main>
    )
}


const Main = styled.div`
    /* padding: 60px; */
    width: 100%;
    background-color: #EEEEEE;
    height: auto;
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
        padding: 0 10px;
        width: 95%;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 20px;
    @media (max-width: 800px) {
    flex-direction: column;

    }
`;

const DownloadButton = styled.button`
    border: none;
    font-family: 'Noto Sans Armenian', sans-serif;
    width: fit-content;
    padding: 6px 20px;
    border-radius: 14px;
    border: 2px solid black;
    margin: 20px 0;
    font-size: 16px;
    cursor: pointer;
    a {
        text-decoration: none;
        color: black;
    }
`;

const Row1 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 800px) {
    flex-direction: column;

    }
`;

const ImageBlock = styled.div`
    /* display: flex; */
    /* height: 100%; */
    width: 25%;
    img {
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
    }
    @media (min-width: 1500px) {
        width: 20%;
    }
    @media (max-width: 800px) {
        display: none;
    }
`;

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    @media (max-width: 800px) {
    width: 100%;
    }
`;

const HeaderText = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    font-family: 'Noto Sans Armenian', sans-serif;
    @media (max-width: 450px) {
        font-size: 20px;
    }
`;
const DescriptionText = styled.p`
    font-family: 'Noto Sans Armenian', sans-serif;
    margin: 20px 0 0 0 ;
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    font-family: 'Noto Sans Armenian', sans-serif;

`;