export const extractImageSrcFromHTML = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
  
    const images = doc.querySelectorAll("img"); // Get all img elements in the document
  
    const imageSrcList = [];
    images.forEach((image) => {
      const src = image.getAttribute("src");
      if (src) {
        imageSrcList.push(src);
      }
    });
  
    return imageSrcList;
  }
  