import React from "react";
import styled from "styled-components";
import banner1 from "../../images/banner1.svg";
import { Link } from 'react-router-dom';


export default function MainBanner() {
    return (
        <Banner>
            <TextBanner>
                <BannerCaption>
                    <Text>Հանրակրթության պետական չափորոշիչ</Text>
                    <br />
                    <Button>
                        <ButtonText><Link to='/page1'>Ավելին</Link></ButtonText>
                    </Button>
                </BannerCaption>
                <BannerCaption>
                    <Text>Ժողովրդավարական մշակույթի կարողունակություններ</Text>
                    <br />
                    <Button>
                        <ButtonText><Link to='/page2'>Ավելին</Link></ButtonText>
                    </Button>
                </BannerCaption>
                <BannerCaption>
                    <Text>Տարեկան աշխատանքային պլան (2023)</Text>
                    <br />
                        <Button to="/year">
                            <ButtonText><Link to='/year'>Ավելին</Link></ButtonText>
                        </Button>
                </BannerCaption>
            </TextBanner>
        </Banner>
    )
}

const Banner = styled.div`
    max-width: 95%;
    display: flex;
    height: 700px;
    width: 95%;
    background: url(${banner1}) center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TextBanner = styled.div`
    display: flex;
    height: 100%;
    width: 35%;
    backdrop-filter: blur(3px);
    flex-direction: column;
    @media (max-width: 1450px) {
        width: 100%;
    }
`;

const BannerCaption = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 30px;
    
`;

const Text = styled.p`
    color: white;
    font-size: 22px;
    font-family: 'Noto Sans Armenian', sans-serif;
    font-weight: 600;
    @media (max-width: 500px) {
        font-size: 18px;
    }
`;

const ButtonText = styled.p`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    margin: 0;
    padding: 0;
    color: #535ba0;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Noto Sans Armenian', sans-serif;
    a {
        text-decoration: none;
        color: #fff;
        font-weight: 500;
        letter-spacing: 1px;
    }
    &:hover{
        color: white;
    }
`;

const Button = styled.button`
    text-align: center;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    transition: .3s;
    text-decoration: none;
    width: 150px;
    height: 50px;
    border: 2px solid #535ba0;
    background: none;
    &:hover{
        background-color: #535ba0;
    }
    @media (max-width: 634px) {
        width: 120px;
        height: 45px;
    }
`;