import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import styled from 'styled-components';
import Facebook from '../images/facebook.svg';
import Blogger from '../images/blogger.svg';
import Youtube from '../images/youtube.svg';
import Logo from '../images/logoWhite.svg';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const [isOpen1, setIsOpen1] = useState(false);

  const toggleMenu1 = () => {
    setIsOpen1(!isOpen1);
  };
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleMenu2 = () => {
    setIsOpen2(!isOpen2);
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          <NavbarEnd>
            <NavbarEndText>ԿԶՆԱԿ</NavbarEndText>
            <NavbarLogo src={Logo}></NavbarLogo>
          </NavbarEnd>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <div className='nav-menu-items'>
            <NavbarTop>
              <SearchBar>
                <Soc>
                  <a rel='noopener noreferrer' target='_blank' href='https://kznakhimnadram.blogspot.com'>
                    <SocIcons src={Blogger}></SocIcons>
                  </a>
                  <a rel='noopener noreferrer' target='_blank' href='https://www.facebook.com/kznakfoundation'>
                    <SocIcons src={Facebook}></SocIcons>
                  </a>
                  <a rel='noopener noreferrer' target='_blank' href='https://www.youtube.com/@user-rs4yt1yp3v'>
                    <SocIcons src={Youtube}></SocIcons>
                  </a>
                </Soc>
              </SearchBar>
              <div className='navbar-toggle' onClick={showSidebar}>
                <Link to='#' className='menu-bars'>
                  <AiIcons.AiOutlineClose />
                </Link>
              </div>
            </NavbarTop>
            {SidebarData.map((item, index) => {
              return (
                <div key={index} className={item.cName}>
                  <Link to={item.path}>
                    {/* {item.icon} */}
                    <span>{item.title}</span>
                  </Link>
                </div>
              );
            })}

            {/* <Toggle1>
              Մասնագիտական զարգացում
            </Toggle1>
            <Toggle1>
              Պաշարների շտեմարան
            </Toggle1> */}

            <MenuButtonContainer>
              <MenuButton onClick={toggleMenu1}>Մասնագիտական զարգացում</MenuButton>
              <HiddenButtons1 isOpen1={isOpen1}>
                <HiddenButton><Links1 href='https://teams.microsoft.com/l/team/19%3ayYyha4bm1jJSIeT6JTT9bJ5IOZWSRwYeTZ5ARF38lCA1%40thread.tacv2/conversations?groupId=a567d4d1-e687-4241-950c-b8a03a104bf4&tenantId=976968cb-c5c0-49d5-8e40-8ced875b4261' rel='noopener noreferrer' target='_blank'>Տնօրեն և տնօրենի տեղակալ</Links1></HiddenButton>
                <HiddenButton><Links1 href='https://teams.microsoft.com/l/team/19%3aGWjEbuHs-5J_LD9loO-WDwFiPauLrjWNmmrTH-kALxE1%40thread.tacv2/conversations?groupId=d1b78a4c-8d60-410f-9721-0654c207e1c9&tenantId=976968cb-c5c0-49d5-8e40-8ced875b4261' rel='noopener noreferrer' target='_blank'>Մեթոդմիավորման նախագահ</Links1></HiddenButton>
                <HiddenButton><Links1 href='https://teams.microsoft.com/l/team/19%3aMQ-kcdxrDWfIfJskVoNaZ4EffoysprMIJ7SLgFzPzy41%40thread.tacv2/conversations?groupId=51d90798-6a6a-4749-bf5a-1acb6713715a&tenantId=976968cb-c5c0-49d5-8e40-8ced875b4261' rel='noopener noreferrer' target='_blank'>Ուսուցիչ</Links1></HiddenButton>
                <HiddenButton><Links1 href='https://teams.microsoft.com/l/team/19%3an88CZUviOId2fBtROvIhPNK8V6sWDvjbZ7S3osnLV2U1%40thread.tacv2/conversations?groupId=91eb6e32-0dfa-42ac-844a-486c3c0aa8a4&tenantId=976968cb-c5c0-49d5-8e40-8ced875b4261' rel='noopener noreferrer' target='_blank'>Լաբորանտ</Links1></HiddenButton>
              </HiddenButtons1>
            </MenuButtonContainer>
            <MenuButtonContainer>
              <MenuButton onClick={toggleMenu2}>Պաշարների շտեմարան</MenuButton>
              <HiddenButtons2 isOpen2={isOpen2}>
                <HiddenButton><Links1 href='https://lib.armedu.am/category/75' rel='noopener noreferrer' target='_blank'>Կառավարում</Links1></HiddenButton>
                <HiddenButton><Links1 href='https://lib.armedu.am/category/76' rel='noopener noreferrer' target='_blank'>Գնահատում</Links1></HiddenButton>
                <HiddenButton><Links1 href='https://lib.armedu.am/category/71' rel='noopener noreferrer' target='_blank'>Դասերի պլաններ և օրինակելի թեմատիկ պլաններ</Links1></HiddenButton>
                <HiddenButton><Links1 href='https://lib.armedu.am/category/70' rel='noopener noreferrer' target='_blank'>Ուսումն․ նախագծերի երկարօրյայի և խմբակի ծրագրեր</Links1></HiddenButton>
              </HiddenButtons2>
            </MenuButtonContainer>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;


const Links1 = styled.a`
  text-decoration: none;
  font-family: 'Noto Sans Armenian', sans-serif;
  color: #fff;
  &:hover {
    color: #3474d4;
  }
`;

const MenuButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
`;

const MenuButton = styled.button`
  color: #fff;
  border: 2px solid #494949;
  padding: 10px 15px;
  border-radius: 15px;
  background: none;
  font-family: 'Noto Sans Armenian', sans-serif;
  font-size: 1.1rem;
  margin: 10px 0 0 20px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  &:hover{
    color: #3474d4;
  }
`;

const HiddenButtons1 = styled.div`
  display: ${props => (props.isOpen1 ? 'flex' : 'none')};
  top: 100%;
  color: #fff;
  flex-direction: column;
  margin: 0 0 0 28px;
  left: 0;
  background: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const HiddenButtons2 = styled.div`
  position: absolute;
  display: ${props => (props.isOpen2 ? 'flex' : 'none')};
  top: 100%;
  color: #fff;
  flex-direction: column;
  margin: 0 0 0 28px;
  left: 0;
  background: none;
`;

const HiddenButton = styled.button`
  display: block;
  width: 100%;
  color: #fff;
  font-family: 'Noto Sans Armenian', sans-serif;
  text-align: start;
  padding: 10px 0;
  font-size: 1.1rem;
  background: none;
  border: none;
  cursor: pointer;
  &:hover{
    color: #3474d4;
  }
`;

/////////////


const NavbarTop = styled.div`
  height: 100px;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

const NavbarEnd = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: absolute;
  right: 0;
`;

const NavbarEndText = styled.p`
  color: white;
  font-family: 'Noto Sans Armenian', sans-serif;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 2px;
  @media (max-width: 380px) {
    display: none;
  }
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100px;
  width: 100%;
  background-color: #2d2e39;
  padding: 0;
`;

const Soc = styled.div`
  display: flex;
  width: 100%;
  padding: 0 28px 0 28px;
  justify-content: space-between;
  flex-direction: row;
  /* margin-top: 40px; */
`;

const SocIcons = styled.img`
  height: 60px;
  width: 60px;
`;

const NavbarLogo = styled.img`
  height: 90px;
  width: 90px;
  margin-left: 25px;
`;