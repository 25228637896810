import React from 'react'
import styled from 'styled-components';

function Year() {
    return (
        <Main>
            {/* /////////////////////////////////////1///////////////////////////////////// */}
            <Header>
                {1}
                <HeaderContent>
                    Տավուշի մարզում նոր չափորոշիչների և ծրագրերի փորձարկում։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Գնահատել ՀՊՉ և առարկայական ծրագրերի խնդիրները 2,3,5,6,7,8,10,11
                        դասարաններում 01-07 ամիսների ընթացքում և 1,4,9,12 դասարաններում 09-12
                        ամիսների ընթացքում։</li>
                    <li>Աջակցել խնդիրների վերացմանը։</li>
                    <li>Տրամադրել խորհրդատվություն։</li>
                    <li>Առաջարկություններ ներկայացնել ՀՊՉ և ԱՉԾ մեջ փոփոխություններ կատարելու համար։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////2///////////////////////////////////// */}
            <Header>
                {2}
                <HeaderContent>
                    ՀՀ 9 մարզում և Երևան քաղաքում նոր չափորոշիչների և ծրագրերի ներդրում 2,5,7,10-րդ դասարաններում։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>ՀՊՉ և առարկայական չափորոշիչների և ծրագրերի վերապատրաստման իրականացման նպատակով ընտրել և ԿԳՄՍՆ հաստատմանը ներկայացնել մենթոր դպրոցներ։</li>
                    <li>ՀՊՉ և առարկայական ծրագրերի վերապատրաստման իրականացման նպատակով մշակել վերապատրաստողների վերապատրաստման ծրագիր։</li>
                    <li>ՀՊՉ և առարկայական ծրագրերի վերապատրաստման իրականացման նպատակով իրականացնել մենթոր դպրոցների տնօրենների, տնօրենի տեղակալների, նախադպրոցական, հումանիտար, բնագիտական մեթոդմիավորումների նախագահների վերապատրաստումներ՝ որպես վերապատրաստողներ։</li>
                    <li>ՀՊՉ և առարկայական ծրագրերի վերապատրաստման իրականացման նպատակով մենթոր դպրոցներում իրականացնել հարակից դպրոցների մանկավարժական աշխատողների վերապատրաստումներ։</li>
                    <li>Կատարել ՀՊՉ և առարկայական ծրագրերի վերապատրաստման իրականացման նպատակով իրականացվող վերապատրաստումների մշտադիտարկում։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////3///////////////////////////////////// */}
            <Header>
                {3}
                <HeaderContent>
                    Գնահատման մեթոդաբանության մշակում։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Մշակել  ՀՊՉ պահանջներից բխող գնահատման մեթոդաբանություն և լրամշակել փորձարկման գնահատման կարգը։</li>
                    <li>Մշակել մեթոդական ուղեցույցներ ձևավորող, միավորային և բնութագրող  գնահատման ձևերի համար։</li>
                    <li>Մշակել գնահատման աշխատակարգի նախագիծ։</li>
                    <li>Մշակել մայրենի, հայոց լեզու, մաթեմատիկա, հանրահաշիվ և երկրաչափություն առարկաների ձևավորող գնահատման բնութագրիչներ 5,7,10-րդ դասարանների համար։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////4///////////////////////////////////// */}
            <Header>
                {4}
                <HeaderContent>
                    Երկարօրյա ուսման մեջ ցածր առաջադիմություն ունեցող սովորողների համար կազմակերպելու մեթոդական աջակցության իրականացում
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Երկարօրյա դասերի կազմակերպման ուղեցույցի մշակում։</li>
                    <li>Երկարօրյա դասերի ընթացքում կիրառվող ուսումնական նյութերի մշակում։</li>
                    <li>Երկարօրյա դասերի ընթացքում կիրառվող ուսումնական նյութերի տպագրություն։</li>
                    <li>Երկարօրյա դասերի կազմակերպման վերապատրաստումների ծրագրի մշակում։</li>
                    <li>Երկարօրյա դասերի կազմակերպման վերապատրաստումների կազմակերպում։</li>
                    <li>Երկարօրյա դասերի արդյունքների գնահատում։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////5///////////////////////////////////// */}
            <Header>
                {5}
                <HeaderContent>
                    Պարտադիր ատեստավորման համար ուսուցիչների վերապատրաստումների արդյունքների գնահատում և վերլուծություն։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Ուսումնասիրել ուսուցիչների վերապատրաստման արդյունքում ստացած գիտելիքների և ձեռք բերած կարողությունների և դասի ընթացքում դրանց կիրառումը։</li>
                    <li>Գնահատել դպրոցի տնօրենի աշխատանքը վերապատրաստման ընթացքը վերահսկելու, վերապատրաստող կազմակերպությունների հետ համագործակցելու, վերապատրաստվող ուսուցիչների աշխատանքը գնահատելու համար։</li>
                    <li>Առաջարկություններ ներկայացնել ԿԳՄՍՆ վերապատրաստող կազմակերպությունների աշխատանքի վերաբերյալ։</li>
                    <li>Աջակցել 2022 թվականի ընթացքում ատեստավորման համար ուսուցիչների վերապատրաստման կազմակերպման աշխատանքներին։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////6///////////////////////////////////// */}
            <Header>
                {6}
                <HeaderContent>
                    Դպրոցների տնօրենների և տեղակալների վերապատրաստումներ։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Կազմակերպել դպրոցների տնօրենների և ՈՒԱԳՏ-ների համար սեմինարներ (առկա կամ հեռավար) «Օրինակելի աշխատանքային պլանի հիման վրա դպրոցի տարեկան աշխատանքային պլան մշակում» թեմայով։</li>
                    <li>Կազմակերպել դպրոցների տնօրենների և ՈՒԱԳՏ-ների սեմինարներ (առկա կամ հեռավար) «ՆԴՎ միջոցառումների օրինակելի աշխատանքային պլանի հիման վրա, դպրոցի ՆԴՎ միջոցառումների պլան մշակում» թեմայով։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////7///////////////////////////////////// */}
            <Header>
                {7}
                <HeaderContent>
                    Նախադպրոցական ուսումնական հաստատությունների տնօրենների և դաստիարակների վերապատրաստումներ։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Կազմակերպել պետական նախադպրոցական հաստատությունների տնօրենների համար սեմինարներ (առկա կամ հեռավար), օրինակելի աշխատանքային պլանի հիման վրա հաստատության ՏԱՊ-ը մշակելու նպատակով՝ օգնելու և իրականացնելու համար։</li>
                    <li>Նախադպրոցական հաստատությունների տնօրենների համար սեմինարներ (առկա կամ հեռավար), վերահսկողության միջոցառումների օրինակելի աշխատանքային պլանի հիման վրա հաստատությունների վերահսկողության միջոցառումների պլան մշակելու և իրականացնելու  նպատակով։</li>
                    <li>ՆԴ  չափորոշչի լրամշակում, համալիր ծրագրի փորձարկում և լրամշակում, նոր չափորոշիչներին համահունչ ուսումնամեթոդական ձեռնարկների մշակում։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////8///////////////////////////////////// */}
            <Header>
                {8}
                <HeaderContent>
                    Ներդպրոցական վերահսկողության կազմակերպման ուղեցույցի մշակում։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Նախադպրոցական ուսումնական հաստատության տնօրենը, հանրակրթական դպրոցի տնօրենը, հանրակրթական դպրոցի տնօրենի ուսումնական աշխատանքի գծով տեղակալը կկարողանան իրականացնել ներդպրոցական վերահսկողություն՝ ըստ դրված նպատակի։
                    </li>
                </ul>
            </Content>
            {/* /////////////////////////////////////9///////////////////////////////////// */}
            <Header>
                {9}
                <HeaderContent>
                    Ընթերցանության որակի ապահովման ծրագրի իրականացում տարրական դպրոցում։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Դպրոցի տնօրենին սովորեցնել առարկայի դրվածքի ուսումնասիրության ալգորիթմը։</li>
                    <li>Բարելավել ընթերցանության ուսուցումը տարրական դպրոցում։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////10///////////////////////////////////// */}
            <Header>
                {10}
                <HeaderContent>
                    Մանկավարժական առաջավոր փորձի ուսումնասիրություն և տարածում
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Ուսումնասիրել հանրակրթական ուսումնական հաստատություններում գոյություն ունեցող առաջավոր փորձը և տարածել այն։</li>
                    <li>Ուսումնասիրել մանկավարժության նոր զարգացումները և տարածել դրանք։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////11///////////////////////////////////// */}
            <Header>
                {11}
                <HeaderContent>
                    Առարկաների դասավանդման մեթոդների ներկայացման առցանց հարթակի ստեղծում
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Ուսուցիչների համար մեթոդական օժանդակության ապահովում։</li>
                    <li>Առարկայի դասավանդման որակի բարձրացում։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////12///////////////////////////////////// */}
            <Header>
                {12}
                <HeaderContent>
                    Կրթություն հանուն կայուն զարգացման։ Փոխակերպվող կրթություն
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Հիմք ընդունելով ՅՈՒՆԵՍԿՕ-ի առաջարկությունները՝ մշակել քաղաքականության նախագիծ, որն աջակցում է փոխակերպվող կրթության ինտեգրմանը կրթության ոլորտում։
                    </li>
                </ul>
            </Content>
            {/* /////////////////////////////////////13///////////////////////////////////// */}
            <Header>
                {13}
                <HeaderContent>
                    Հայրենագիտության ուսումնական նախագիծ մարզերի դպրոցների սովորողների փոխանակմամբ։
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Ուսուցիչներին ներկայացնել ուսումնակա նախագծեր մշակել և իրականացնել։</li>
                    <li>Ստեղծել սովորողների համար հանրապետությունում շարժումանկության հնարավորություն։</li>
                </ul>
            </Content>
            {/* /////////////////////////////////////14///////////////////////////////////// */}
            <Header>
                {14}
                <HeaderContent>
                    Սովորողների մոտ մեդիագրագիտության հմտությունների զարգացում
                </HeaderContent>
            </Header>
            <Content>
                <ul>
                    <li>Հասարակագիտական առարկաների՝ չափորոշչով սահմանված վերջնարդյունքի հիման վրա սովորողների մոտ բարելավել մեդիագրագիտության մակարդակը։</li>
                </ul>
            </Content>
        </Main>
    )
}

export default Year;

const Main = styled.div`
    padding: 60px 40px 0 40px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
    padding-left: 10px;
    padding-right: 10px;
    
  }
`;

const Header = styled.div`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: 27px;
    color: white;
    height: 60px;
    display: flex;
    padding: 20px;
    width: 96%;
    /* margin-bottom: 20px; */
    background-color: #031952;
    justify-content: space-between;
    align-items: center;
    /* border: 4px blue solid; */
    /* filter: drop-shadow(0 2px 5px black); */

`;

const HeaderContent = styled.div`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: 24px;
    color: white;
    height: 100%;
    display: flex;
    width: 98%;
    background-color: #031952;
    align-items: center;
    /* border: 4px blue solid; */
    /* filter: drop-shadow(0 2px 5px black); */
    @media (max-width: 1100px) {
        width: 92%;
    }
`;

const Content = styled.div`
    font-family: 'Noto Sans Armenian', sans-serif;
    color: black;
    padding: 20px;
    margin-bottom: 20px;
    width: 96%;
    font-size: 18px;
    transition: all .2s;
    transition: 1s ease;
`;