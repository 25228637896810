import React from 'react'
import BlueLogo from '../../images/logoBlue.svg'
import Mission from '../../images/mission.jpg'
import Xndir from '../../images/xndir.jpg'
import Grakanutyun from '../../images/grakanutyun.jpg'
import Verapatrastum from '../../images/verapatrastum22.jpg'
import styled, { css } from 'styled-components'
import { useState } from 'react'

export default function Header() {

    const [show1, setShow1] = useState("0%");
    const [show2, setShow2] = useState("0%");
    const [show3, setShow3] = useState("0%");
    const [show4, setShow4] = useState("0%");

    const Hidden = [
        {
            opacity: show1,
        },
        {
            opacity: show2
        },
        {
            opacity: show3,
        },
        {
            opacity: show4
        }
    ];

    const full = {
        opacity: "100%"
    };

    const TextStart = {
        textAlign: "start",
        lineHeight: "30px"
    };



    return (
        <Main>
            <Row>
                <Column>
                    <MainText style={TextStart}>Կրթության զարգացման և նորարարությունների ազգային կենտրոն</MainText>
                    <Text style={TextStart}> Մեր գործառույթներն են մշակել մանկավարժական աշխատողների աշխատանքի արդյունավետությունը բարձրացնող ռազմավարություններ, մեթոդներ, գործիքներ և դրանց կիրառման ուղեցույցներ, աջակցել մանկավարժական աշխատողների վերապատրաստումների իրականացման պահանջների և վերապատրաստման ծրագրերի չափանիշների մշակմանը, սովորողների մասնագիտական կողմնորոշման գործընթացներին և ծրագրերին։ Կազմակերպել կրթական գործընթացի մասնակիցների մասնագիտական կարողությունների զարգացման միջոցառումներ, դասագրքերի, ձեռնարկների և այլ ուսումնական նյութերի փորձաքննությունը, աջակցել դասագրքերի ընտրության և փորձարկման գործընթացներին։ </Text>
                </Column>
                <ImageDiv />

            </Row>

            <br />
            <br />
            <br />
            <br />

            <Horizonal>
                <Block background1
                    onMouseEnter={() => setShow1("100%")}
                    onMouseLeave={() => setShow1("0%")}
                >
                    <Blur>
                        <TopSection >
                            <FixedText style={full}>Մեր նպատակն է</FixedText>
                        </TopSection>
                        <BottomSection>
                            <FixedHiddenText style={Hidden[0]}>Խթանել կրթության ոլորտի զարգացումը և արդիականացումը՝ ի նպաստ սովորողների որակյալ կրթության ապահովմանը, առողջության և սոցիալական պաշտպանվածության բարելավմանը, մրցունակ անձի, ակտիվ քաղաքացու ձևավորմանը և կրթական մասնագիտական ներուժի զարգացմանը:
                            </FixedHiddenText>
                        </BottomSection>
                    </Blur>
                </Block>
                <Block background2
                    onMouseEnter={() => setShow2("100%")}
                    onMouseLeave={() => setShow2("0%")}
                >
                    <Blur>
                        <TopSection>
                            <FixedText>Մեր խնդիրն է</FixedText>
                        </TopSection>
                        <BottomSection style={Hidden[1]}>
                            <FixedHiddenText>Աջակցել Հայաստանի Հանրապետությունում կրթության ոլորտի բովանդակային, կազմակերպական, կառուցվածքային և միջավայրային կատարելագործման, գիտահետազոտական, մեթոդական և փորձարարական սպասարկման, փորձագիտական երաշխավորման և գնահատման, մասնագետների և ղեկավար կադրերի մասնագիտական զարգացման ապահովմանը։</FixedHiddenText>
                        </BottomSection>
                    </Blur>
                </Block>
                <Block background3
                    onMouseEnter={() => setShow3("100%")}
                    onMouseLeave={() => setShow3("0%")}
                >
                    <Blur>
                        <TopSection>
                            <FixedText>Մանկավարժական գրականություն</FixedText>
                        </TopSection>
                        <BottomSection style={Hidden[2]}>
                            <FixedHiddenText>Մանկավարժությունը անընդհատ զարգացող գիտություն։ Մենք հետևում ենք այդ զարգացումներին և մանկավարժական աշխատողներին ենք տրամադրում մեթոդական գրականություն։</FixedHiddenText>
                        </BottomSection>
                    </Blur>
                </Block>
                <Block background4
                    onMouseEnter={() => setShow4("100%")}
                    onMouseLeave={() => setShow4("0%")}
                >
                    <Blur>
                        <TopSection>
                            <FixedText>Վերապատրաստումներ</FixedText>
                        </TopSection>
                        <BottomSection style={Hidden[3]}>
                            <FixedHiddenText>Մանկավարժական աշխատողները կարիք ունեն շարունակական վերապատրաստումների։ Մենք ապահովում ենք բոլոր մանկավարժական աշխատողներին առկա և հեռավար վերապատրաստումներին մասնակցելու հնարավորություն։</FixedHiddenText>
                        </BottomSection>
                    </Blur>
                </Block>
            </Horizonal>
        </Main >
    )
}

const Main = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    text-align: center;
    justify-content: center;
    @media (max-width: 900px) {
        width: 100%;
    }
`;

const Text = styled.p`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: 20px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    height: auto;
    align-items: center;
    @media (max-width: 1300px){
       flex-direction: column;
    }
`;
const MainText = styled.h1`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: 36px;
    font-weight: 700;
    @media (max-width: 700px){
        font-size: 30px;
    }
`;


const ImageDiv = styled.div`
    height: 300px;
    width: 300px; 
    background-image: url(${BlueLogo});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;



const Horizonal = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    align-items:center;
    justify-content:center;
    @media(max-width: 1300px){
        flex-direction: column;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    transition: 0.6s;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 450px;
    &:hover{
        width: 200%;
        @media (max-width: 1300px){
            width: 100%;
        }
    }
    ${props => props.background1 && css`
        background: url(${Mission}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
    ${props => props.background2 && css`
        background: url(${Xndir}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
    ${props => props.background3 && css`
        background: url(${Grakanutyun}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
    ${props => props.background4 && css`
        background: url(${Verapatrastum}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
`;

const Blur = styled.div`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    display: flex;
    flex-direction: column;
    transition: 1s;
    &:hover{
        /* backdrop-filter: blur(5px); */
        /* background-color: rgba(0, 0, 0, 0.4); */
    }
`;

const TopSection = styled.div`
    display: flex;
    flex-direction: row;
    transition: 0.6s ease-out;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 20%;
    min-height: 20%;
`;

const BottomSection = styled.div`
    display: flex;
    flex-direction: row;
    transition: 0.6s ease-out;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 95%;
    height: 75%;
`;

const FixedText = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    font-size: 19px;
    line-height: 30px;
    color: white;
    font-size: auto;
    filter: drop-shadow(0 2px 5px black);
`;

const FixedHiddenText = styled.h4`
    width: 80%;
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    font-size: 17px;
    line-height: 30px;
    color: white;
    filter: drop-shadow(0px 2px 5px black);
    font-size: auto;
    @media (max-width: 700px) {
        line-height: 22px;
        font-size: 16px;
    }
`;