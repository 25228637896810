import React from 'react'
import { styled } from 'styled-components';
import UnescoBG from '../../images/bgImageUnesco.webp'

export default function Header() {
    return (
        <Main>
            <Blur>
                {/* <HeaderText>
                UNESCO Associated Schools Network
            </HeaderText> */}
                <HeaderText>
                    UNESCO ասոցացված դպրոցներ
                </HeaderText>
                < br />
                < br />
                < br />
                < br />
                <DescriptionText>
                    ՅՈՒՆԵՍԿՕ-ի ասոցացված դպրոցների ցանցը (ASPnet) միավորում է 182 երկրների ավելի քան 12000 դպրոցներ երեխաների և երիտասարդների: Կոնկրետ գործողությունների միջոցով անդամ դպրոցները նպաստում են ՅՈՒՆԵՍԿՕ-ի իրավունքների և արժանապատվության, գենդերային հավասարության, սոցիալական առաջընթացի, ազատության, արդարության և ժողովրդավարության, բազմազանության նկատմամբ հարգանքի և միջազգային համերաշխության իդեալներին: Ցանցը գործում է միջազգային և ազգային մակարդակներում երեք հստակ առաջնահերթություններով՝ կրթություն հանուն կայուն զարգացման, գլոբալ քաղաքացիության կրթություն և միջմշակութային և ժառանգական ուսուցում:
                </DescriptionText>
            </Blur>
        </Main>
    )
}

const Main = styled.div`
    /* padding: 60px; */
    width: 100%;
    height: 450px;
    /* height: auto; */
    display: flex;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(${UnescoBG});
    background-position: center;
    background-size: cover;
    @media (max-width: 1000px) {
        padding: 0 10px;
        height: auto;
    }
    @media (max-width: 1500px) {
        height: auto;
    }
`;


const Blur = styled.div`
    flex-direction: column;
    height: 100%;
    border-radius: 10px;
    align-items: center;
    width: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.415);
    backdrop-filter: blur(2px);
    @media (max-width: 600px) {
        padding: 10px;
        /* height: auto; */
    }
`;

const HeaderText = styled.p`
    font-size: 38px;
    font-family: 'Noto Sans Armenian', sans-serif;
    color: white;
    text-shadow: 1px 2px 3px black;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
`;

const DescriptionText = styled.p`
    font-size: 22px;
    font-family: 'Noto Sans Armenian', sans-serif;
    color: white;
    font-weight: 500;
    padding: 0 30px;
    text-shadow: 1px 2px 3px black;
    @media (max-width: 600px) {
        padding: 0;
    }
`;