import React from "react";
import styled from "styled-components";
import Advice from "./home/Advice.jsx";
import MainBanner from "./home/mainBanner.jsx";
import Services from "./home/Services.jsx";
import Slide from "./home/slider";
import Slide2 from "./home/slider2.jsx";

export default function Home() {
    return (
        <Main>
            <MainBanner />
            <Space>
                <SpaceBr />
            </Space>
            {/* <Services /> */}
            {/* <Space>
                <SpaceBr />
            </Space> */}
            <Slide />
            <Space>
                <SpaceBr />
            </Space>
            <Slide2 />
            <Space>
                <SpaceBr />
            </Space>
            <Advice />
        </Main>
    )
}

const Main = styled.div`
    padding: 60px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Space = styled.div`
    display: flex;
    width: 100%;
    height: 120px;
    border-radius: 10px;
    align-items: center;
    @media (max-width: 1000px) {
        height: 60px;
    }
`;
const SpaceBr = styled.div`
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    border: 1px #eeeeee solid;
    border-radius: 10px;
`;