import React from 'react'
import styled from 'styled-components';
import Header from './page2/header';
import Part1 from './page2/part1';
import Part2 from './page2/part2';
import Part3 from './page2/part3';



export default function Page2() {
    return (
        <Main>
            <Header />
            <Space>
                <SpaceBr />
            </Space>
            <Part1 />
            <Space>
                <SpaceBr />
            </Space>
            <Part2 />
            <Space>
                <SpaceBr />
            </Space>
            <Part3 />
        </Main>
    )
}

const Main = styled.div`
    padding: 25px 60px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
    padding-left: 10px;
    padding-right: 10px;
    }
`;

const Space = styled.div`
    display: flex;
    width: 100%;
    height: 120px;
    border-radius: 10px;
    align-items: center;
`;
const SpaceBr = styled.div`
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    border: 1px #eeeeee solid;
    border-radius: 10px;
`;