import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Icon1 from "../../images/icon_01.png"
import Icon2 from "../../images/icon_02.png"
import Icon3 from "../../images/icon_03.png"
import Icon1Hover from "../../images/icon_hover_01.png"
import Icon2Hover from "../../images/icon_hover_02.png"
import Icon3Hover from "../../images/icon_hover_03.png"


export default function Services() {
    const [iconColor, setIconColor] = useState(false);
    const [iconColor1, setIconColor1] = useState(false);
    const [iconColor2, setIconColor2] = useState(false);
    const iconStyles = {
        backgroundImage: (Icon1),
    };
    return (
        <Main>
            <Adjust>

                <Row
                    onMouseEnter={() => setIconColor(true)}
                    onMouseLeave={() => setIconColor(false)}
                >
                    <RowDecoration>
                        <IconDiv
                            src={iconColor ? Icon1Hover : Icon1} />
                        <Text>Շարունակական մասնագիտական զարգացում (ՇՄԶ)</Text>
                    </RowDecoration>
                </Row>
                <Row
                    onMouseEnter={() => setIconColor1(true)}
                    onMouseLeave={() => setIconColor1(false)}
                >
                    <RowDecoration>
                        <IconDiv
                            src={iconColor1 ? Icon2Hover : Icon2}
                        />
                        <Text>Հանրակրթության պետական չափորոշիչ (ՀՊՉ)</Text>
                    </RowDecoration>
                </Row>
                <Row
                    onMouseEnter={() => setIconColor2(true)}
                    onMouseLeave={() => setIconColor2(false)}
                >
                    <RowDecoration>
                        <IconDiv style={iconStyles}
                            src={iconColor2 ? Icon3Hover : Icon3}
                        />
                        <Text>Կարողունակությունների զարգացում</Text>
                    </RowDecoration>
                </Row>
            </Adjust>
        </Main>
    )
}



const Main = styled.div`
    display: flex;
    width: 100%;
    max-width: 95%;
    height: auto;
    justify-content: space-between;
`;

const Adjust = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35vh;
    justify-content: space-between;
    @media (max-width: 900px){
        flex-direction: column;
        height: 90vh;
    }
    @media (max-width: 530px){
        flex-direction: column;
        height: 100vh;
    }
`;

const Row = styled.div` 
    height: 100%;
    transition: all .5s;
    width: 33%;
    max-width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: #eaebef;
    /* padding-left: 30px;
    padding-right: 30px; */
    &:hover{
        background-image: linear-gradient(to right, #3d619b, #3d75af, #3e8ac3, #3f9fd5, #43b4e6);
        color: white;
    }
    /* justify-content:space-evenly; */
    @media(max-width: 900px){
        padding: 0;
        padding-top: 15px;
        width: 100%;
        margin-bottom: 3px;
    }
`;

const RowDecoration = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 95%;
    width: 95%;
    padding: 30px 35px 10px 35px;
    @media(max-width: 900px){
        padding: 15px 35px 10px 35px;
    }
`;

const IconDiv = styled.img`
    display: flex;
    height: 60px;
    width: 60px;
    align-items: center;
    justify-content: center;
    transition: all 1s;

    background-size: cover;
    background-repeat: no-repeat;
    /* background: url(${Icon1}); */
    /* background: url(${Icon1}); */
`;

const Text = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: 22px;
    text-align: center;
    margin-bottom: 50px; 
`;