import React from "react";
import styled from "styled-components";
import Header from "./page1/header";
import Part1 from "./page1/part1";

export default function Page1() {

    return (
        <Main>
            <Header />
            <Space>
                <SpaceBr />
            </Space>
            <Part1 />
        </Main>
    )
}

const Main = styled.div`
    padding: 25px 60px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
    padding-left: 10px;
    padding-right: 10px;
    }
`;

const Space = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    align-items: center;
`;

const SpaceBr = styled.div`
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    border: 1px #eeeeee solid;
    border-radius: 10px;
`;