import React, {useState} from 'react'
import styled from 'styled-components';


function ImageSlider2({slides}) {

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  return (
    <SliderStyles>
      <MainBlock>
        {/* href={slides[currentIndex].url} */}
        <Slider>
          <LeftButton className="w3-button w3-black w3-display-left" onClick={goToPrevious}>&#10094;</LeftButton>
          <img src={slides[currentIndex].image} alt=""/>
          <RightButton className="w3-button w3-black w3-display-right" onClick={goToNext}>&#10095;</RightButton>
        </Slider>
      </MainBlock>
      <SecondBlock>
        <Name>
          <HeaderText>Վերջին հրապարակումներ</HeaderText>
        </Name>
        <TitleDiv>
          <ContentText>
            {slides[currentIndex].title}
          </ContentText>
        </TitleDiv>
      </SecondBlock>
    </SliderStyles>
  )
}

export default ImageSlider2;


const ContentText = styled.p`
  margin: 0;
  font-size: 20px;
  font-family: Noto Sans Armenian, sans-serif;
  @media (max-width: 1050px) {
    height: 80px;
    font-size: 16px;
  }
  @media (max-width: 800px) {
    height: 100px;
  }
  @media (max-width: 500px) {
    height: 150px;
  }
`;

const HeaderText = styled.p`
  font-family: Noto Sans Armenian, sans-serif;
  font-size: 25px;
  padding: 0;
  margin: 0;
  @media (max-width: 1050px) {
    margin: 0 0 15px 0;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
  }
`;


const TitleDiv = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  text-align: start;
  padding-left: 10px;
  @media (max-width: 1000px) {
    align-items: unset;
    padding-left: 0;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;

const SecondBlock = styled.div`
  justify-content: space-between;
  display: flex;
  padding-left: 20px;
  width: 40%;
  flex-direction: column;
  @media (max-width: 1000px) {
    align-items: center;
    flex-direction: column;
    padding-left: 0;
    width: 100%;
    height: auto;
    justify-content: space-between;
  }
`;

const SliderStyles = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const Name = styled.div`
  display: flex;
  width: 80%;
  font-size: 28px;
  text-align: start;
  padding-left: 10px;
  font-weight: 600;
  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 0;
  }
`;

const Slider = styled.div`
  width: 95%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 80%;
    max-height: 334px;
    /* height: 100%; */
    object-fit: scale-down;
    @media (max-width: 1000px) {
      max-height: 300px;
    }
    @media (max-width: 500px) {
      max-height: 200px;
      width: 70%;
    }
  }
  
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
  }
`;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 40vh;

  @media (max-width: 1000px) {
    height: auto;
  }
`;

const LeftButton = styled.button`
  height: 40px;
  min-height: 40px;
  background-color: white;
  border: 3px solid rgba(83, 91, 160, 1);
  width: 50px;
  min-width: 50px;
  color: rgba(83, 91, 160, 1);
  font-size: 20px;
  font-weight: 700;
  transition: 0.7s;
  @media (max-width: 426px) {
    width: 30px;
    min-width: 30px;
  }
  &:hover {
    color: white;
    background-color: rgba(83, 91, 160, 1);
  }
`;

const RightButton = styled.button`
  height: 40px;
  min-height: 40px;
  background-color: white;
  border: 3px solid rgba(83, 91, 160, 1);
  width: 50px;
  min-width: 50px;
  color: rgba(83, 91, 160, 1);
  font-size: 20px;
  font-weight: 700;
  transition: 0.7s;
  @media (max-width: 426px) {
    width: 30px;
    min-width: 30px;
  }
  &:hover {
    color: white;
    background-color: rgba(83, 91, 160, 1);
  }
`;

