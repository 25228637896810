import React from 'react';
import './App.css';
import Routing from './components/Routing';

function App() {
  return (
    <Routing/>
  );
}

export default App;