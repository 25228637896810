import React from "react";
import styled from "styled-components";
import Header from "./contact/header";
import Content from "./contact/content";

export default function Contact(){
    return(
        <Main>
            <Header/>
            <Space/>
            <Content/>            
        </Main>
    )
}

const Main = styled.div`
    padding: 60px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1500px) {
    padding: 20px;
  }
    @media (max-width: 1000px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Space = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    align-items: center;
`;