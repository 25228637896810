import React from 'react';
import styled from 'styled-components';
import BgImage from '../../images/banner1.svg'

// Styled Components
const Container = styled.div`
  font-family: Arial, sans-serif;
  border-radius: 10px;
  background-color: #f4f4f4;
  padding: 20px;
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const SectionTitle = styled.h2`
    font-family: 'Noto Sans Armenian', sans-serif;
  font-size: 24px;
  color: white;
  margin-top: 0;
  @media (max-width: 400px) {
    font-size: 20px;
}
`;

const ListItem = styled.p`
  font-size: 16px;
    font-family: 'Noto Sans Armenian', sans-serif;
  color: white;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;


const Section = styled.div`
  background-color: #fff;
  padding: 20px;
  background-color: rgba(170, 170, 170, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  @media (max-width: 400px) {
    padding: 0 10px;
    margin: 0;
}
ul {
  padding: 0;
}
`;

const LinkStyles = styled.a`
    color: lightblue;
    text-shadow: 1px 2px 2px 0.1 black;
`;


// Functional Component
export default function Content() {
    return (
        <Container>
            <Section>
                <SectionTitle>Ասոցացված դպրոցների նպատակը</SectionTitle>
                <ul>
                    <ListItem>
                        Ասոցացված դպրոները հանդես են գալիս որպես կրթության որակի, նորարարության և փոխակերպման գաղափարների լաբորատորիա <LinkStyles rel='noopener noreferrer' target='_blank' href='https://armenia.un.org/hy/sdgs/4' >Կայուն Զարգացման 4-րդ նպատակի 7-րդ</LinkStyles> թիրախի համար:
                    </ListItem>
                </ul>
            </Section>

            <Section>
                <SectionTitle>1. Դպրոցները՝ որպես գաղափարների լաբորատորիաներ</SectionTitle>
                <ul>
                    <ListItem>
                        Խթանելով նորարարությունն ու կրթության փոխակերպումը ուժեղացնել դպրոցները այնպես, որ հանդես գան որպես լաբորատորիաներ գլոբալ քաղաքացիության, միջմշակութային փոխըմբռնման և կայունության համար:
                    </ListItem>
                </ul>
            </Section>

            <Section>
                <SectionTitle>2. Համաշխարհային ցանց և համագործակցություն</SectionTitle>
                <ul>
                    <ListItem>
                        Ամպապնդել երկրների և դպրոցների միջև միջազգային համագործակցությունը, գիտելիքների փոխանակուը և գործընկերութույնը:
                    </ListItem>
                </ul>
            </Section>

            <Section>
                <SectionTitle>3. Կարողությունների զարգացում</SectionTitle>
                <ul>
                    <ListItem>
                        Նորարարական և մասնակցային ուսուցման համար ապահովել ինստիտուցիոնալ և մարդկային կարողությունների զարգացում:
                    </ListItem>
                </ul>
            </Section>

            <Section>
                <SectionTitle>4. Հաղորդակցություն և շահերի պաշտպանություն</SectionTitle>
                <ul>
                    <ListItem>
                        Աջակցել փոխակերպվող կրթության և կրթության որակի բարելավմանը ասոցացված դպրոցների շրջանում և դրա սահմաններից դուրս՝ հասարակության և քաղաքականություն մշակողների հետ:
                    </ListItem>
                </ul>
            </Section>
        </Container>
    );
}