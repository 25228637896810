import React, { useState } from 'react'
import styled from 'styled-components';
import RandomImage from '../../images/banner1.svg'


import Image1_1 from '../../images/page1-1/1.jpg'
import Image1_2 from '../../images/page1-1/2.jpg'
import Image1_3 from '../../images/page1-1/3.jpg'
import Image1_4 from '../../images/page1-1/4.jpeg'

import Image2_1 from '../../images/page1-3/1.jpg'
import Image2_2 from '../../images/page1-3/2.jpg'
import Image2_3 from '../../images/page1-3/3.JPG'
import Image2_4 from '../../images/page1-3/4.jpeg'

import Image3_1 from '../../images/page1-2/1.jpg'
import Image3_2 from '../../images/page1-2/2.JPG'
import Image3_3 from '../../images/page1-2/3.jpg'
import Image3_4 from '../../images/page1-2/4.jpg'


export const images1 = [
    Image1_1,
    Image1_2,
    Image1_3,
    Image1_4
];
export const images2 = [
    Image2_1,
    Image2_2,
    Image2_3,
    Image2_4
];

export const images3 = [
    Image3_1,
    Image3_2,
    Image3_3,
    Image3_4
];
export const buttons = [
    {
        name: '2021 - 2022 ուս տարի',
        number: 1
    },
    {
        name: '2022 - 2023 ուս տարի',
        number: 2
    },
    {
        name: '2023 - 2024 ուս տարի',
        number: 3
    },
    {
        name: '2024 - 2025 ուս տարի',
        number: 4
    },
    {
        name: '2025 - 2026 ուս տարի',
        number: 5
    }
];

export const text1 = [
    'ՀՊՉ փորձարկում Տավուշի մարզի ուսումնական հաստատությունների 2-րդ, 5-րդ, 7-րդ և 10-րդ դասարաններում',
    'Ուսումնական նախագծերի կազմակերպում Տավուշի մարզի ուսումնական հաստատությունների 7-րդ և 10-րդ դասարաններում',
    'Երկարօրյա դասերի կազմակերպում Տավուշի մարզի ուսումնական հաստատությունների 2-րդ, 5-րդ և 7-րդ  դասարաններում'
];
export const text2 = [
    'ՀՊՉ փորձարկում Տավուշի մարզի ուսումնական հաստատությունների 2-րդ, 3-րդ, 5-րդ, 6-րդ, 7-րդ, 8-րդ, 10-րդ և 11-րդ դասարաններում',
    'Ուսումնական նախագծերի կազմակերպում Տավուշի մարզի ուսումնական հաստատությունների 7-րդ, 8-րդ, 10-րդ և 11-րդ դասարաններում',
    'Երկարօրյա դասերի կազմակերպում Տավուշի մարզի ուսումնական հաստատությունների 2-րդ, 3-րդ, 5-րդ, 6-րդ, 7-րդ և 8-րդ  դասարաններում'
];
export const text3 = [
    'ՀՊՉ փորձարկում Տավուշի մարզի ուսումնական հաստատությունների 1-12-րդ դասարաններում',
    'Ավարտական քննություններ Տավուշի մարզի ուսումնական հաստատությունների 9-րդ և 12-րդ դասարաններում',
    'Ուսումնական նախագծերի կազմակերպում Տավուշի մարզի ուսումնական հաստատությունների 7-12-րդ դասարաններում և հանրապետության մյուս ուսումնական հաստատությունների 7-րդ դասարաններում',
    'Երկարօրյա դասերի կազմակերպում Տավուշի մարզի ուսումնական հաստատությունների 2-9-րդ  դասարաններում և հանրապետության մյուս ուսումնական հաստատությունների 2-րդ, 5-րդ և 7-րդ դասարաններում',
    'ՀՊՉ ներդրում հանրապետության բոլոր ուսումնական հաստատությունների 2-րդ, 5-րդ և 7-րդ դասարաններում'
];
export const text4 = [
    'ՀՊՉ ներդրում հանրապետության բոլոր ուսումնական հաստատությունների 2-րդ, 3-րդ, 5-րդ, 6-րդ, 7-րդ, 8-րդ և 10-րդ դասարաններում',
    'Ուսումնական նախագծերի կազմակերպում հանրապետության ուսումնական հաստատությունների 7-րդ, 8-րդ և 10-րդ դասարաններում',
    'Երկարօրյա դասերի կազմակերպում հանրապետության ուսումնական հաստատությունների 2-րդ, 3-րդ, 5-րդ, 6-րդ, 7-րդ և 8-րդ դասարաններում'
];
export const text5 = [
    'ՀՊՉ ներդրում հանրապետության բոլոր ուսումնական հաստատությունների 2-9-րդ և 10-11-րդ դասարաններում',
    'Ուսումնական նախագծերի կազմակերպում հանրապետության ուսումնական հաստատությունների 7-11-րդ դասարաններում',
    'Երկարօրյա դասերի կազմակերպում հանրապետության ուսումնական հաստատությունների 2-9-րդ դասարաններում',
    'Ավարտական քննություններ հանրապետության ուսումնական հաստատությունների 9-րդ և 12-րդ դասարաններում'
];


export default function Part1() {
    

    const [currentIndex, setCurrentIndex] = useState(3);
    const setIndex = (index) => {
        currentIndex === index ? setCurrentIndex(0) : setCurrentIndex(index);
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const openPreview = imageUrl => {
        setSelectedImage(imageUrl);
    };

    const closePreview = () => {
        setSelectedImage(null);
    };

    return (
        <Main>
            <MainBlock>
                <Blur>
                    <HeaderText>Հանրակրթության պետական չափորոշչի ներդրման ժամանակացույց</HeaderText>
                    <ButtonSection>
                        {buttons.map((current, index) => (
                            <YearButton style={{
                                backgroundColor: currentIndex === current.number ? 'white' : '',
                                color: currentIndex === current.number ? 'black' : ''
                            }} onClick={() => setIndex(current.number)} key={index}>
                                {current.name}
                            </YearButton>
                        ))}
                    </ButtonSection>
                    <DescriptionSection>
                        <DescriptionText>
                            {currentIndex === 1 &&
                                <ul>
                                    {text1.map((text) => (
                                        <li>
                                            {text}
                                        </li>
                                    ))}
                                </ul>}

                            {currentIndex === 2 &&
                                <ul>
                                    {text2.map((text) => (
                                        <li>
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            }
                            {currentIndex === 3 &&
                                <ul>
                                    {text3.map((text) => (
                                        <li>
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            }
                            {currentIndex === 4 &&
                                <ul>
                                    {text4.map((text) => (
                                        <li>
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            }
                            {currentIndex === 5 &&
                                <ul>
                                    {text5.map((text) => (
                                        <li>
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </DescriptionText>
                        {currentIndex === 1 &&
                            <DescriptionImages>
                                {images1.map((image, index) => (
                                    <ImageContainer key={index} onClick={() => openPreview(image)}>
                                        <img src={image} alt={`${index}`} />
                                    </ImageContainer>
                                ))}
                            </DescriptionImages>

                        }
                        {currentIndex === 2 &&
                            <DescriptionImages>
                                {images2.map((image, index) => (
                                    <ImageContainer key={index} onClick={() => openPreview(image)}>
                                        <img src={image} alt={`${index}`} />
                                    </ImageContainer>
                                ))}
                            </DescriptionImages>
                        }
                        {currentIndex === 3 &&
                            <DescriptionImages>
                                {images3.map((image, index) => (
                                    <ImageContainer key={index} onClick={() => openPreview(image)}>
                                        <img src={image} alt={`${index}`} />
                                    </ImageContainer>
                                ))}
                            </DescriptionImages>
                        }
                        {/* {currentIndex === 4 &&
                            <DescriptionImages>
                                {images1.map((image, index) => (
                                    <ImageContainer key={index} onClick={() => openPreview(image)}>
                                        <img src={image} alt={`${index}`} />
                                    </ImageContainer>
                                ))}
                            </DescriptionImages>
                        }
                        {currentIndex === 5 &&
                            <DescriptionImages>
                                {images1.map((image, index) => (
                                    <ImageContainer key={index} onClick={() => openPreview(image)}>
                                        <img src={image} alt={`${index}`} />
                                    </ImageContainer>
                                ))}
                            </DescriptionImages>
                        } */}

                    </DescriptionSection>
                </Blur>
            </MainBlock>
            {
                selectedImage && (
                    <ImagePreview isOpen={true}>
                        <CloseButton onClick={closePreview}>&times;</CloseButton>
                        <img src={selectedImage} alt="" />
                    </ImagePreview>
                )
            }
        </Main >
    )
}

const ImagePreview = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  img {
    object-fit: scale-down;
    max-width: 70%;
    max-height: 70%;
    border-radius: 15px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 50px;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  margin: 10px;
  cursor: pointer;
  width: 33%;
  height: 200px;
  @media (max-width: 800px) {
    width: 40%;
  }
  img {
        border-radius: 10px;
        box-shadow: 2px 2px 4px black;
        max-height: 148px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin: 10px 0;
    }
`;

const DescriptionSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    @media (max-width: 1440px) {
        flex-direction: column;
    }
`;

const DescriptionImages = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
    width: 45%;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 1440px) {
        width: 80%;
    }
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    @media (max-width: 1440px) {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    /* @media (max-width: 1700px) {
        flex-direction: column;
        width: fit-content;
    } */
`;

const YearButton = styled.button`
    height: 45px;
    border-radius: 14px;
    transition: 1s all;
    font-family: 'Noto Sans Armenian', sans-serif;
    cursor: pointer;
    font-size: 17px;
    background: none;
    border: 1px solid white;
    color: white;
    padding: 0 25px 0 25px;
    :active {
        background-color: white;
        color: black;    
    }
    &:hover {
        background-color: white;
        color: black;
    }
    @media (max-width: 1700px) {
        margin-top: 10px;
    }
`;

const Main = styled.div`
    /* padding: 60px; */
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: space-between;
    @media (max-width: 1000px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const MainBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    filter: drop-shadow(4px 4px 10px #535ba0);
    background-image: url(${RandomImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Blur = styled.div`
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    background-color: #00000050;
    height: 100%;
    padding: 15px 40px 15px 40px;
    backdrop-filter: blur(7px);
`;

const HeaderText = styled.p`
    font-size: 25px;
    font-weight: 700;
    color: white;
    font-family: 'Noto Sans Armenian', sans-serif;
`;

const DescriptionText = styled.p`
    font-size: 21px;
    font-weight: 300;
    line-height: 30px;
    width: 55%;
    color: white;
    font-family: 'Noto Sans Armenian', sans-serif;
    @media (max-width: 1440px) {
        width: 100%;
    }
    li {
        margin-bottom: 20px;
    }
`;