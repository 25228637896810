export const SidebarData = [
  {
    title: 'Գլխավոր',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'Հանրակրթության պետական չափորոշիչ',
    path: '/page1',
    cName: 'nav-text'
  },
  {
    title: 'Ժողովրդավարական մշակույթի կարողունակություններ',
    path: '/page2',
    cName: 'nav-text'
  },
  {
    title: 'UNESCO ասոցացված դպրոցների ցանց',
    path: '/page3',
    cName: 'nav-text'
  },
  {
    title: 'Մեր Մասին',
    path: '/about',
    cName: 'nav-text'
  },
  {
    title: 'Կապ',
    path: '/contact',
    cName: 'nav-text'
  }
];