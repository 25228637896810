import React from 'react'
import styled, { css } from 'styled-components';
import { useState } from 'react';
import Progress from "../../images/progres.jpg"
import Inovation from "../../images/inovation.jpg"
import Research from "../../images/research.jpg"


export default function Slider2() {

    const [show1, setShow1] = useState("0%");
    const [show2, setShow2] = useState("0%");
    const [show3, setShow3] = useState("0%");

    const Hidden = [
        {
            opacity: show1,
        },
        {
            opacity: show2
        },
        {
            opacity: show3,
        }
    ];

    const Start = {
        textAlign: "start"
    }

    return (
        <Main>
            <Text>Կրթական նորարարությունների և հետազոտության վարչություն</Text>
            <SmallText>Կրթության նորարարությունների և հետազոտությունների վարչությունը հանդիսանում է նոր գիտելիքների կենտրոն հանրակրթության բոլոր դերակատարների համար՝ ապահովելով ռեսուրսների, կապերի և հնարավորությունների հոսք:</SmallText>
            <br/>
            <br/>
            <br/>
            <br/>
            <Horizonal>
                <Block background1
                    onMouseEnter={() => setShow1("100%")}
                    onMouseLeave={() => setShow1("0%")}
                >
                    <Blur>
                        <Section>
                            <FixedText>Ուսումնական հաստատության զարգացման կառավարման բաժին</FixedText>
                        {/* https://kznakbnagavar.blogspot.com/ */}
                        </Section>
                        <Section style={Start}>
                            <FixedHiddenText style={Hidden[0]}>
                                <ul>
                                    Մշակում է
                                    <br />
                                    <br />
                                    <li>դպրոցի կառավարման մոդելներ,</li>
                                    <li>ուսումնական հաստատությունում ուսումնական գործընթացի պլանավորման և իրականացման մեթոդական ուղեցույցներ,</li>
                                    <li>ներդպրոցական վերահսկողության ռազմավարություններ,</li>
                                    <li>դպրոցի գործունեության իրավական փաստաթղթերի լրամշակման առաջարկություններ,</li>
                                    <li>դպրոցի կառուցվածքը, ընթացիկ աշխատանքները նկարագրող մեթոդական ուղեցույցներ:</li>
                                </ul>
                            </FixedHiddenText>
                        </Section>
                        <Section>
                            <Button>
                                <ButtonText>Ավելին</ButtonText>
                            </Button>
                        </Section>
                    </Blur>
                </Block>
                <Block background2
                    onMouseEnter={() => setShow2("100%")}
                    onMouseLeave={() => setShow2("0%")}
                >
                    <Blur>
                        <Section>
                            <FixedText>Կրթական նորարարությունների բաժին</FixedText>
                        </Section>
                        <Section style={Start}>
                            <FixedHiddenText style={Hidden[1]}>
                                <ul>
                                    Ուսումնասիրում է
                                    <br />
                                    <br />
                                    <li>կրթության համաշխարհային զարգացումները և նորարական մոտեցումները, ներդնում ուսումնական հաստատություններում,</li>
                                    <li>ուսումնական հաստատություններում իրականացվող նորարարական ծրագրերը, իրականացնում նախագծերի մշտադիտարկում և գնահատում։
                                        <br />
                                        <br />
                                    </li>
                                    Մշակում է
                                    <br />
                                    <br />
                                    <li>սովորողների մասնագիտական կողմնորոշման գործընթացների մեթոդաբանություն և ծրագրեր,
                                    </li>
                                    <li>մասնագիտական կրթության և ուսուցման ոլորտի գրավչության բարձրացմանը նպաստող միջոցառումներ։
                                    </li>
                                </ul>
                            </FixedHiddenText>
                        </Section>
                        <Section>
                            <Button>
                                <ButtonText>Ավելին</ButtonText>
                            </Button>
                        </Section>
                    </Blur>
                </Block>
                <Block background3
                    onMouseEnter={() => setShow3("100%")}
                    onMouseLeave={() => setShow3("0%")}
                >
                    <Blur>
                        <Section>
                            <FixedText>Կրթության ոլորտի հետազոտությունների բաժին</FixedText>
                        </Section>
                        <Section style={Start}>
                            <FixedHiddenText style={Hidden[2]}>
                                <ul>
                                    Ուսումնասիրում է
                                    <br />
                                    <br />
                                    <li>կրթության համակարգը,</li>
                                    <li>կրթության որակի, սովորողների անձնային աճի, արժեհամակարգի ձևավորման և բնականոն զարգացման վրա կրթական ծրագրերի բովանդակության և ծավալի ազդեցությունը,
                                    </li>
                                    <li>սոցիալ-հոգեբանական և ֆիզիկական միջավայրը։
                                    </li>
                                </ul>
                            </FixedHiddenText>
                        </Section>
                        <Section>
                            <Button>
                                <ButtonText>Ավելին</ButtonText>
                            </Button>
                        </Section>
                    </Blur>
                </Block>
            </Horizonal>
        </Main>
    )
}


const Main = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Text = styled.h1`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    color: black;
    font-size: 24px;
`;
const SmallText = styled.p`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    color: black;
    font-size: 20px;
`;

const Blur = styled.div`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    display: flex;
    flex-direction: column;
    transition: 1s;
    &:hover{
        /* backdrop-filter: blur(5px); */
        /* background-color: rgba(0, 0, 0, 0.4); */

    }
`;

const Horizonal = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    align-items:center;
    justify-content:center;
    @media(max-width: 1000px){
        flex-direction: column;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    transition: 0.6s;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 700px;
    &:hover{
        backdrop-filter: blur(15px);
        width: 200%;
        @media (max-width: 1000px){
            width: 100%;
        }
    }
    ${props => props.background1 && css`
        background: url(${Progress}) center;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(15px);
        background-size: cover;
        background-repeat: no-repeat;
    `};
    ${props => props.background2 && css`
        background: url(${Inovation}) center;
        backdrop-filter: blur(15px);
        background-size: cover;
        background-repeat: no-repeat;
    `};
    ${props => props.background3 && css`
        backdrop-filter: blur(15px);
        background: url(${Research}) center;
        background-size: cover;
        background-repeat: no-repeat;
    `};
`;

const Section = styled.div`
    display: flex;
    flex-direction: row;
    transition: 0.6s ease-out;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 30%;
`;

const FixedText = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    font-size: 20px;
    color: white;
    filter: drop-shadow(0px 2px 5px black);
    font-size: auto;
`;

const FixedHiddenText = styled.h4`
    font-family: 'Noto Sans Armenian', sans-serif;
    transition: 0.7s;
    font-size: 17px;
    line-height: 22px;
    color: white;
    filter: drop-shadow(0px 2px 5px black);
    font-size: auto;
    @media (max-width: 700px) {
        line-height: 19px;
        font-size: 16px;
    }
`;

const ButtonText = styled.p`
    transition: 1s;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 20px;
    font-family: 'Noto Sans Armenian', sans-serif;
    &:hover{
        color: white;
    }
`;

const Button = styled.button`
    text-align: center;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    transition: 1s;
    width: 150px;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid white;
    filter: drop-shadow(4px 4px 10px #535ba0);
    background: none;
    &:hover{
        background-color: #535ba0;
    }
`;
