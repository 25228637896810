import React from 'react'
import styled from 'styled-components';
import * as FaIcons from 'react-icons/fa';
import * as HiIcons from 'react-icons/hi';
import * as GrIcons from 'react-icons/gr';

const IconColor = {
    color: "#2d7aff",
    marginRight: "10px"
}


function Header() {
    return (
        <Main>
            <OurContacts>
                Մեր Կոնտակտները
            </OurContacts>
            <InfoDiv>
                <SmallDiv><FaIcons.FaPhoneAlt style={IconColor}/>010 57-21-00</SmallDiv>
                <SmallDiv><GrIcons.GrMail style={IconColor}/>info@kznak.am</SmallDiv>
                <SmallDiv><HiIcons.HiLocationMarker style={IconColor}/>Տիգրան Մեծ 67</SmallDiv>
            </InfoDiv>
        </Main>
    )
}

export default Header;

const Main = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    @media (max-width: 1500px) {
        flex-direction: column;
    }
`;

const OurContacts = styled.div`
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: 25px;
    width: 40%;
    display: flex;
    font-weight: 700;
    height: 50px;
    text-align: center;
    align-items: center;
    @media (max-width: 1500px) {
        justify-content: center;
        width: 100%;
        height: 80px;
    }
`;

const InfoDiv = styled.div`
    font-size: 25px;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    display: flex;
    text-align: center;
    align-items: center;
    @media (max-width: 1500px) {
        width: 100%;
    }
    @media (max-width: 800px) {
        width: 100%;
        flex-direction: column;
    }
`;

const SmallDiv = styled.div`
    /* font-family: 'Noto Sans Armenian', sans-serif; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 25px;
    font-weight: 100;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    width: 33%;
    height: 50px;
    display: flex;
    align-items: center;
    letter-spacing: .5px;
    @media (max-width: 800px) {
        width: 100%;
        height: 70px;
    }
`;
